<template>
  <div v-show="remembermeAd == 'false'">
    <v-container fluid class="hello">
      <v-row>
        <v-col
          cols="12"
          md="6"
          lg="6"
          xl="6"
          align="center"
          class="hidden-sm-and-down ma-0 pa-0"
          style="margin: 0px"
        >
          <v-img
            class="image"
            src="@/assets/HomeImage.png"
            :height="customImgHeight"
          ></v-img>

          <!-- <p absoulte
            style="color:#0FA7D8;font-size:80px;font-family:'Kanit', sans-serif;margin-top:650px"
            class="text-center position-sticky" 
          >Optimistic</p>-->
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6" class="hello-right">
          <div class="right-text-container">
            <v-container fluid>
              <v-row>
                <v-col cols="12" align="right">
                  <v-spacer></v-spacer>
                  <v-menu
                    offset-y
                    origin="center center"
                    :nudge-bottom="10"
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn text dark v-on="on" @click="changeLang">
                        <country-flag :country="currentflag" size="normal" />
                        <span
                          class="text-capitalize lang-title hidden-sm-and-down"
                          >&nbsp;&nbsp;{{ currentLangTitle }}</span
                        >
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item-group v-model="lang" color="#F89D1F">
                        <v-list-item
                          v-for="entry in languages"
                          :key="entry.title"
                          @click="
                            changeLocale(
                              entry.language,
                              entry.flag,
                              entry.title
                            )
                          "
                        >
                          <v-list-item-action>
                            <country-flag :country="entry.flag" size="normal" />
                          </v-list-item-action>
                          <v-list-item-title>{{
                            entry.title
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" align="center">
                  <p class="text-center welcomeToHeader">Welcome to</p>
                  <br />
                  <p class="text-center optimisticHeader" style>OPTIMISTIC</p>
                </v-col>
              </v-row>

              <v-row>
                <v-col xl="3" lg="2" md="2" sm="2"></v-col>
                <v-col cols="12" lg="8" xl="6" md="8" sm="8">
                  <v-alert
                    style="text-align: center"
                    type="error"
                    v-model="erroralert"
                    >{{
                      errorMessage ==
                      "You have failed logging in for 5 times already.Please click on Forgot Password to reset and try again"
                        ? $t("YouHaveFailed")
                        : $t("InvalidUsernameAndPassword")
                    }}</v-alert
                  >
                  <v-alert
                    style="text-align: center"
                    v-if="remembermeAd"
                    type="info"
                    v-model="successalert"
                    >{{ successMessage }}</v-alert
                  >
                </v-col>
                <v-col xl="3" lg="2" md="2" sm="2"></v-col>
                <v-spacer></v-spacer>
              </v-row>

              <v-row justify="center" class="rowjust">
                <v-col cols="12" style="flex-basis: 500px">
                  <v-text-field
                    color="warning"
                    class="textFieldCustom"
                    :label="$t('username')"
                    v-model="input.username"
                    dense v-on:keydown.enter="login()"
                  >
                    <template v-slot:prepend-inner>
                      <img
                        width="24"
                        height="24"
                        src="@/assets/icons/Mask Group 140.svg"
                        alt
                      />
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center" class="rowjust">
                <v-col cols="12" style="flex-basis: 500px">
                  <v-text-field
                    color="warning"
                    :label="$t('password')"
                    class="textFieldCustom"
                    v-model="input.password"
                    :type="show1 ? 'text' : 'password'"
                    dense v-on:keydown.enter="login()"
                  >
                    <template v-slot:prepend-inner>
                      <img
                        width="24"
                        height="24"
                        src="@/assets/icons/Mask Group 141.svg"
                        alt
                      />
                    </template>
                    <template v-slot:append>
                      <v-btn icon color="white">
                        <img
                          width="40"
                          height="40"
                          @click="show1 = !show1"
                          :src="show1 ? pwdimage.showpwd : pwdimage.hidepwd"
                          alt 
                        />
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center" class="rowjust">
                <v-col cols="12" style="flex-basis: 500px">
                  <v-checkbox
                    :label="$t('rememberMe')"
                    required
                    v-model="rememberMe"
                    class="text"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col
                  cols="12"
                  sm="12"
                  xs="12"
                  style="flex-basis: 428px; align-items: center"
                >
                  <v-btn
                    rounded
                    color="#F99D20"
                    block
                    @click="login()"
                    :loading="loading"
                    style="height: 52px; font-size: 17px; color: white"
                    depressed
                    class="text-capitalize kanit"
                    >{{ $t("login") }}</v-btn
                  >
                </v-col>
              </v-row>
              <v-row align="center" class="ml-md-8">
                <v-col cols="1"></v-col>
                <v-col cols="9" align="center">
                  <a
                    class="kanit font17"
                    style="color: #0fa7d8"
                    @click="gotoforget()"
                    >{{ $t("forgetpsw") }}</a
                  >
                </v-col>
                <v-col cols="1"></v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
      </v-row>
      
      <LoadingDialog :LoadingDialog="dialog"></LoadingDialog>

    </v-container>
  </div>
</template>

<script>
import CryptoJS from "crypto-js";
import CountryFlag from "vue-country-flag";
import enurl from "@/api/environment";
import axios from "axios";
import i18n from "@/translate/i18n";
import LoadingDialog from "@/components/Loading.vue";
export default {
  components: { CountryFlag, LoadingDialog },
  data() {
    return {
      secretKey: "OptimisticSecretPassphrase@2020",
      rememberMe: false,
      expiryDate: "",
      remembermeAd: "false",
      checkuser: null,
      currentflag: "us",
      currentLangTitle: "English",
      lang: 1,
      url: enurl.apiUrl,
      checkuserCount: 1,
      languages: [
        { flag: "th", language: "th", title: "ประเทศไทย" },
        { flag: "us", language: "en", title: "English" },
        { flag: "CN", language: "cn", title: "中文" },
        { flag: "VN", language: "vn", title: "Tiếng Việt" },
        { flag: "MM", language: "mm", title: "မြန်မာဘာသာ" },
      ],
      is_Locked: false,

      customImgHeight: "100%",
      input: {
        username: "",
        password: "",
      },
      pwdimage: {
        showpwd: require("@/assets/images/reveal icon@2x.png"),
        hidepwd: require("@/assets/images/pass@2x.png"),
      },
      erroralert: false,
      successalert: false,
      successMessage: "",
      errorMessage: "",
      loading: false,
      dialog: false,
      show1: false,

      userList: [],
    };
  },
  created() {
    let self = this;
    let login_username = self.readCookie("username");
    let login_password = self.readCookie("password");
    self.remembermeAd = self.readCookie("remembermeAd");

    if (self.remembermeAd == "true") {
      self.expiryDate = self.readCookie("expiredate");
      let today = new Date();
      if (today >= self.expiryDate) {
        document.cookie = "username=" + ";expires=" + self.expiryDate;
        document.cookie = "password=" + ";expires=" + self.expiryDate;
        document.cookie =
          "remembermeAd='false'" + ";expires=" + self.expiryDate;
      } else {
        if (login_username != null && login_password != null) {
          self.input.username = self.cryptoMethods("decrypt", login_username);
          self.input.password = self.cryptoMethods("decrypt", login_password);
          // self.remembermeAd;
          self.login();
        }
      }
    } else {
      self.remembermeAd = "false";
      self.expiryDate = new Date();
      self.expiryDate.setMonth(self.expiryDate.getMonth() + 1);
      document.cookie = `expiredate=${self.expiryDate};expires=${self.expiryDate}`;
    }
  },
  mounted() {
    if (sessionStorage.locale == null) {
      i18n.locale = "en";
    } else {
      i18n.locale = sessionStorage.getItem("locale");
    }

    if (sessionStorage.flag == null) {
      this.currentflag = "us";
    } else {
      this.currentflag = sessionStorage.getItem("flag");
    }

    if (sessionStorage.title == null) {
      this.currentLangTitle = "English";
    } else {
      this.currentLangTitle = sessionStorage.getItem("title");
    }
  },
  methods: {
    cryptoMethods(type, value) {
      if (value != null || value != "" || value != undefined) {
        if (type == "encrypt") {
          const encryptedText = CryptoJS.AES.encrypt(
            value,
            this.secretKey
          ).toString();
          return encryptedText;
        } else {
          const decryptedText = CryptoJS.AES.decrypt(
            value,
            this.secretKey
          ).toString(CryptoJS.enc.Utf8);
          return decryptedText;
        }
      }
    },
    readCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
    changeLang() {
      if (sessionStorage.locale == "th") {
        this.lang = 0;
      } else if (
        sessionStorage.locale == "us" ||
        sessionStorage.locale == null
      ) {
        this.lang = 1;
      } else if (sessionStorage.locale == "cn") {
        this.lang = 2;
      } else if (sessionStorage.locale == "vn") {
        this.lang = 3;
      } else if (sessionStorage.locale == "mm") {
        this.lang = 4;
      }
    },
    changeLocale(locale, flag, title) {
      this.currentflag = flag;
      i18n.locale = locale;
      this.currentLangTitle = title;
      document.cookie = "lang=" + locale;
      document.cookie = "flag=" + flag;
      document.cookie = "title=" + title;
      sessionStorage.setItem("locale", locale);
      sessionStorage.setItem("flag", flag);
      sessionStorage.setItem("title", title);
    },
    login: function () {
      let self = this;
      self.is_Locked = false;
      self.clearMessage();
      self.loading = true;
      let userName = self.input.username.toLowerCase();
      let password = self.input.password;
      self.getLock();
      if(
        self.input.username != null &&
        self.input.username != "" &&
        self.input.password != null &&
        self.input.password != ""
      ){
        if (self.is_Locked == true) {
          self.loading = false;
          self.errorMessage = false;
        }
        if (self.is_Locked == false) {
          self.$store
            .dispatch("loginAdmin", { userName, password })
            .then(function (response) {
              self.successalert = true;

              if (response.data.message == "Successfully") {
                //console.log(response.data.message)
                self.successMessage = self.$t("successlogin");
                document.cookie =
                  "username=" +
                  self.cryptoMethods("encrypt", userName) +
                  ";expires=" +
                  self.expiryDate;
                document.cookie =
                  "password=" +
                  self.cryptoMethods("encrypt", password) +
                  ";expires=" +
                  self.expiryDate;

                let tempRem =
                  self.rememberMe || self.remembermeAd == "true"
                    ? "true"
                    : "false";
                document.cookie =
                  "remembermeAd=" + tempRem + ";expires=" + self.expiryDate;
                self.loading = false;
                self.model = { username: "", password: "" };
                setTimeout(() => {
                  self.$router.push({ name: "CompanyManagement" }).catch(()=>{});
                }, 1000);
              }
            })
            .catch(function () {
              if (self.checkuserCount == 4) {
                let temp = {
                  username: userName,
                };
                axios
                  .post(`${self.url}UserInfo/LockAccount`, temp)
                  .then(function (response) {
                    self.erroralert = true;
                    self.errorMessage = response.data.message;
                    self.loading = false;
                  });
              } else {
                self.erroralert = true;
                if (self.checkuser == self.input.username) {
                  self.checkuserCount++;
                } else {
                  self.checkuserCount = 1;
                }
                if (self.is_Locked == true) {
                  self.erroralert = true;
                  self.errorMessage =
                    "You have failed logging in for 5 times already.Please click on Forgot Password to reset and try again";

                  self.loading = false;
                } else {
                  self.errorMessage = "Invalid Username and Password";
                }
                self.checkuser = self.input.username;
              }
              self.loading = false;
            });
        }
      } else {
        alert("Please fill in the required fields!");
        self.loading = false;
      }
    },
    clearMessage: function () {
      this.erroralert = false;
      this.successalert = false;
      this.errorMessage = "";
      this.successMessage = "";
    },
    gotoforget() {
      this.$router.push({ name: "forgetpassword" }).catch(()=>{});
    },
    getLock() {
      let self = this;
      if (self.input.username != null) {
        let userName = self.input.username.toLowerCase();
        let tempLock = {
          username: userName,
        };
        axios
          .post(`${self.url}UserInfo/GetUserbyCompanyName`, tempLock)
          .then(function (response) {
            let temp = response.data.data;
            self.is_Locked = temp[0].is_Locked;
          });
      }
    },
  },
};
</script>

<style scoped>
.lang-title {
  color: #f89d1f;
  font-size: 16px;
  font-family: Kanit, sans-serif;
}

.welcomeToHeader {
  color: #0fa7d8;
  font-size: 40px;
  font-family: "Montserrat", sans-serif;
  text-shadow: 0px 3px 6px #00000029;
  font-weight: bold;
}
.optimisticHeader {
  font-size: 60px;
  color: #f99d20;
  font-family: "Montserrat", sans-serif;
  margin-top: -30px;
  font-weight: bold;
}
@font-face {
  font-family: "Montserrat", sans-serif;
  src: url("../assets/fonts/Montserrat-Regular.ttf");
}
.hello-left {
  padding: 0px !important;
  margin: 0px;
  background-repeat: no-repeat;
  background-size: cover;
  height: 120vh;
  background-color: #fff2e3;
}
.right-container {
  width: 100%;
  height: 900px;
  background-color: #fff2e3;
  margin: 0px;
  padding: 0px;
}
.inner-right-container {
  width: 100%;
  height: auto;
  background-color: #fff2e3;
  padding: 0px;
  margin: 0px;
}
.image {
  min-height: 100vh;
  width: 100%;
  height: 100vh;
  max-width: 100%;
  max-height: 200vh;
}
.image-container {
  padding: 0;
  margin: 0;
  width: 100%;
  max-height: 200vh;
  max-width: 100%;
}
.container {
  padding: 0px;
}
.hello {
  background-color: #fff2e3;
  padding-left: 10px;
  padding-right: 10px;
}
@media (min-width: 1179px) {
  .hello-right {
    margin-left: 0px;
  }
}

@media (max-height: 800px) and (max-width: 1240px) {
  .image {
    min-height: 150vh;
    width: 100%;
    height: 120vh;
    max-width: 100%;
    max-height: 200vh;
  }
}

@media (max-height: 600px) and (max-width: 350px) {
  .welcomeToHeader {
    color: #0fa7d8;
    font-size: 40px;
    font-family: "Montserrat", sans-serif;
    text-shadow: 0px 3px 6px #00000029;
    font-weight: bold;
  }
  .optimisticHeader {
    font-size: 60px;
    color: #f99d20;
    font-family: "Montserrat", sans-serif;
    margin-top: -30px;
    font-weight: bold;
  }
}

@media (max-height: 660px) and (max-width: 300px) {
  .welcomeToHeader {
    color: #0fa7d8;
    font-size: 10px;
    font-family: "Montserrat", sans-serif;
    text-shadow: 0px 3px 6px #00000029;
    font-weight: bold;
  }
  .optimisticHeader {
    font-size: 20px;
    color: #f99d20;
    font-family: "Montserrat", sans-serif;
    margin-top: -30px;
    font-weight: bold;
  }
}
@media (max-height: 570px) and (max-width: 370px) {
  .welcomeToHeader {
    color: #0fa7d8;
    font-size: 30px;
    font-family: "Montserrat", sans-serif;
    text-shadow: 0px 3px 6px #00000029;
    font-weight: bold;
  }
  .optimisticHeader {
    font-size: 40px;
    color: #f99d20;
    font-family: "Montserrat", sans-serif;
    margin-top: -30px;
    font-weight: bold;
  }
}

@media (min-height: 820px) and (min-width: 410px) {
  .hello-right {
    margin-left: 0px;
    height: 104vh;
  }
}
@media (min-height: 810px) and (min-width: 370px) {
  .hello-right {
    margin-left: 0px;
    height: 106vh;
  }
}
</style>

<style>
.kanit {
  font-family: Kanit;
}
.textFieldCustom {
  width: 500px;
}
.font17 {
  font-size: 17px;
}
.textFieldCustom .v-text-field__slot {
  padding-bottom: 5px;
}
.qr-custom .v-icon.v-icon {
  color: #3c4043 !important;
}
.form {
  min-width: 50%;
}
</style>
