<template>
  <nav>
    <div v-if="closeAllExpand">
       <!-- !isExpand -->
      <div v-if="!isExpand">
        <v-navigation-drawer
          v-model="drawer"
          :permanent="$vuetify.breakpoint.mdAndUp"
          :clipped="$vuetify.breakpoint.lgAndUp"
          app
          color="#80CCE5"
          dark
          width="280px"
        >
          <v-list dense>
            <v-list-item class="mt-5">
              <v-avatar
                width="100"
                height="100"
                style="
                  margin-left: auto;
                  margin-right: auto;
                  border: 5px solid #f99d20;
                "
              >
                <v-avatar
                  width="90"
                  height="90"
                  style="margin-left: auto; margin-right: auto"
                >
                  <img
                    src="@/assets/images/Group 3166.png"
                    style="border: 5px solid #80cce5"
                  />
                </v-avatar>
              </v-avatar>
            </v-list-item>
            <div style="text-align: center; color: #fff">
              <label
                class="text-capitalize"
                style="font-size: 18px; font-family: 'Kanit', 'sans-serif' "
                >{{ username }}</label
              >
              <br />
              <div class="d-flex" style="margin-left: 108px">
                <img
                  src="@/assets/images/online_active.png"
                  width="17px"
                  height="17px;"
                  class="mt-1"
                />
                <p style="font-size: 14px; color: #32f34c">Active</p>
              </div>
            </div>
            <hr/>
            <template>
              <template v-for="item in items">
                <v-list-group
                  v-model="item.model" 
                  v-if="!item.children"
                  :append-icon="null"
                  :key="item.text"
                  color="#F89D1F" 
                  mandatory
                  @click.stop="get2nd(item.getid)"
                  router :to="item.path"
                  aria-expanded="false"
                  class="my-1"
                  active-class="highlighted"
                  :class="item.path === $route.path || $route.path.indexOf(item.path) > -1 ? 'highlighted' : ''"
                > 
                  <template v-slot:activator >
                    <v-list-item class="pa-0">
                      <v-list-item-icon class="pa-0">
                        <v-img
                          class="ml-2"
                          height="20"
                          width="20"
                          :src="item.img"
                          alt
                          v-if="item.img"
                        ></v-img>
                        <v-icon 
                          class="ml-2"
                          height="20"
                          width="20"
                          alt
                          v-if="!item.img">{{ item.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content  style="padding: 0px">
                        <v-list-item-title class="mt-1 ml-2">{{
                          item.text
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list-group>
                <v-list-group
                  v-else
                  v-model="item.model"
                  :key="item.getid"
                  color="#F89D1F"
                  @click="get1st(item.getid)"
                  active-class="highlighted"
                  :class="checkItems(item.getid) ? 'highlighted' : ''"
                  
                >
                  <template v-slot:append>
                    <img
                      width="40"
                      height="40"
                      @click="show1 = !show1"
                      :src="show1 ? dwnimage.showdwn : dwnimage.hidedwn"
                      alt
                    />
                  </template>
                  <template v-slot:activator >
                    <v-img
                      class="ml-2"
                      height="20"
                      width="20"
                      :src="item.img"
                      alt
                    ></v-img>
                    <v-list-item style="margin-left: 18px">
                      <v-list-item-content>
                        <v-list-item-title class="mt-1 ml-2">{{
                          item.text
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-list-item
                    v-for="(child, i) in item.children"
                    :key="i"
                    exact
                    v-model="getfirst"
                    active-class="highlightedC"
                    :class="
                        child.path === $route.path ||
                        $route.path.indexOf(
                          item.children.map((x) => x.path)[i]
                        ) > -1
                          ? 'highlightedC'
                          : ''
                      "
                  >
                    <v-list-item-content style="padding: 0">
                      <v-list-item router :to="child.path">
                        <v-list-item-title style="margin-left: 40px">
                          {{ child.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </template>
            </template>
          </v-list>
        </v-navigation-drawer>
      </div>

      <!-- isExpand -->
      <div
        v-if="isExpand"
        @mouseover="mouseOver = true"
        @mouseleave="mouseOver = false"
      >
        <v-navigation-drawer
          v-model="drawer"
          :clipped="$vuetify.breakpoint.lgAndUp"
          permanent
          app
          color="#80CCE5"
          dark
          width="280px"
          expand-on-hover
        >
          <v-list class="mt-3" dense>
            <template>
              <template v-for="item in items">
                <v-list-group
                  v-model="item.model" 
                  v-if="!item.children"
                  :append-icon="null"
                  :key="item.text"
                  color="#F89D1F" 
                  mandatory
                  @click.stop="get2nd(item.getid)"
                  router :to="item.path"
                  aria-expanded="false"
                  class="py-1"
                  active-class="highlighted"
                  :class="item.path === $route.path || $route.path.indexOf(item.path) > -1 ? 'highlighted' : ''"
                > 
                  <template v-slot:activator >
                    <v-list-item class="pa-0" >
                      <v-list-item-icon class="pa-0">
                        <v-img
                          height="20"
                          width="20"
                          :src="item.img"
                          alt
                          v-if="item.img"
                        ></v-img>
                        <v-icon
                          height="20"
                          width="20"
                          alt
                          v-if="!item.img">{{ item.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content  style="padding: 0px">
                        <v-list-item-title class="mt-1 ml-2">{{
                          item.text
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list-group>
                <v-list-group
                  v-model="item.model" 
                  v-else
                  :key="item.getid"
                  color="#F89D1F"
                  @click="get1st(item.getid)"
                  active-class="highlighted"
                  :class="checkItems(item.getid) ? 'highlighted' : ''"
                >
                  <template v-slot:append>
                    <img
                      width="40"
                      height="40"
                      @click="show1 = !show1"
                      :src="show1 ? dwnimage.showdwn : dwnimage.hidedwn"
                      alt
                    />
                  </template>
                  <template v-slot:activator>
                    <v-img 
                      height="20"
                      width="20"
                      :src="item.img"
                      alt
                    ></v-img>
                    <v-list-item style="margin-left: 18px">
                      <v-list-item-content>
                        <v-list-item-title class="mt-1 ml-2">{{
                          item.text
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-list-item
                    v-for="(child, i) in item.children"
                    :key="i"
                    exact
                    v-model="getfirst"
                    style="margin-left: 24px"
                  >
                    <v-list-item-content style="padding: 0">
                      <v-list-item router :to="child.path">
                        <v-list-item-title style="margin-left: 40px">
                          {{ child.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </template>
            </template>
          </v-list>
        </v-navigation-drawer>
      </div>
    </div>
    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="white">
      <img
        @click.stop="drawer = !drawer"
        src="@/assets/images/logo@2x.png"
        width="40"
        height="40"
      />
      <v-toolbar-title class="ml-3 pr-2 mr-2">
        <label class="hidden-sm-and-down" style="color: #0fa7d8"
          >Optimistic</label
        >
      </v-toolbar-title>
      <v-toolbar-title class="ml-2 mr-2"> </v-toolbar-title>
      <div>
        <v-btn
          icon
          @click="
            (drawer = !drawer), (isExpand = !isExpand), (closeAllExpand = true)
          "
        >
          <img width="18px;" height="18px;" :src="image" />
        </v-btn>
      </div>
      <div class="hidden-sm-and-down">
        <v-btn icon @click="closeAllExpand = !closeAllExpand">
          <img
            width="20px;"
            height="20px;"
            :src="closeAllExpand ? expandImage.notExpand : expandImage.expand"
          />
        </v-btn>
      </div>
      <v-spacer></v-spacer>

      <div>
        <v-menu
          offset-y
          origin="center center"
          :nudge-bottom="10"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn text dark v-on="on" @click="changed">
              <country-flag :country="currentflag" size="normal" />
              <span class="text-capitalize lang-title hidden-sm-and-down"
                >&nbsp;&nbsp;{{ currentLangTitle }}</span
              >
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group v-model="lang" color="#F99D20">
              <v-list-item
                v-for="entry in languages"
                :key="entry.title"
                @click="changeLocale(entry.language, entry.flag, entry.title)"
              >
                <v-list-item-action>
                  <country-flag :country="entry.flag" size="normal" />
                </v-list-item-action>
                <v-list-item-title>{{ entry.title }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </div>
      <Noti />
      <v-btn text color="#F99D20" class="text-capitalize headtxt">{{
        username
      }}</v-btn>

      <v-menu offset-y min-width="200">
        <template v-slot:activator="{ on }">
          <v-btn color="#FFFFFF" depressed dark v-on="on" class="headddl">
            <img
              width="15px"
              height="15px"
              src="@/assets/images/Mask Groups.png"
              style="margin-right: 1rem"
            />
          </v-btn>
        </template>

        <v-list flat>
          <v-list-item @click="logout()">
            <v-list-item-title
              style="color: #f99d20; border-bottom: 1px solid #f1f1f1"
            >
              <v-icon color="#F99D20">mdi-logout</v-icon
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{ $t("Logout") }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-footer app color="#80CCE5" class="white--text" height="30px"></v-footer>
  </nav>
</template>

<script>
import i18n from "@/translate/i18n";
import CountryFlag from "vue-country-flag";
import Noti from "./Notification";
import store from "../store";
export default {
  components: {
    CountryFlag,
    Noti,
  },
  data: () => ({
    show1: false,
    isExpand: false,
    mouseOver: false,
    closeAllExpand: true,
    username: store.state.username,
    currentflag: "us",
    currentLangTitle: "English",
    dialog: false,
    drawer: null,
    lang: 1,
    thaiFlagIcon: true,
    engFlagIcon: false,
    expandImage: {
      expand: require("@/assets/images/Group 3720.png"),
      notExpand: require("@/assets/images/Icon ionic-ios-qr-scanner.png"),
    },
    dwnimage: {
      showdwn: require("@/assets/images/Mask Group 162@2x.png"),
      hidedwn: require("@/assets/images/Mask Group 161@2x.png"),
    },
    languages: [
      { flag: "th", language: "th", title: "ประเทศไทย" },
      { flag: "us", language: "en", title: "English" },
      { flag: "CN", language: "cn", title: "中文" },
      { flag: "VN", language: "vn", title: "Tiếng Việt" },
      { flag: "MM", language: "mm", title: "မြန်မာဘာသာ" },
    ],
    images: {
      announcement: require("@/assets/images/megaphone.png"),
      edit: require("@/assets/images/logicon.png"),
      report: require("@/assets/images/report.png"),
      vers: require("@/assets/images/version.png"),
    },
    getfirst: 0,
    setting: require("@/assets/images/Mask Group 149@2x.png"),
    image: require("@/assets/images/Icon material-menu.png"),
  }),
  computed: {
    items(){
      return [
        {
          getid: 0,
          icon: "mdi-home",
          text:  this.$t("companyManagement"),
          model: false,
          Check: false,
          path: "/companymanagement"
        },
        { 
          getid: 1,
          icon: "mdi-account-outline",
          text: this.$t("userManagement"),
          model: false,
          Check: false,
          path: "/usermanagement"
        },
        { 
          getid: 2,
          img: this.images.announcement,
          text: this.$t("announcements"),
          model: false,
          Check: false,
          path: "/Announcement"
        },
        { 
          getid: 3,
          img: this.images.edit,
          text: "Log",
          model: false,
          Check: false,
          path: "/log"
        },
        // { 
        //   getid: 4,
        //   img: this.images.report,
        //   text: this.$t("Report"),
        //   model: false,
        //   Check: false,
        //   path: "/Report/usagereportbytype"
        // },
        {
          getid: 4,
          img: this.images.report,
          text: this.$t("Report"),
          model: false,
          Check: false,
          children: [
            {
              text: this.$t("UsageReportByType"),
              path: "/Report/usagereportbytype",
              Checkitem: false,
            },
            {
              text: this.$t("businesstypereport"),
              path: "/Report/BusinessSubscripe",
              Checkitem: false,
            },
          ],
        },
        { 
          getid: 5,
          img: this.images.vers,
          text: this.$t("VersionAndRelease"),
          model: false,
          Check: false,
          path: "/Version"
        },
        {
          getid: 6,
          img: this.setting,
          text: "Setting",
          model: false,
          Check: false,
          children: [
            {
              text: this.$t("DefaultLanguagefor"),
              path: "/DefaultLanguage",
              Checkitem: false,
            },
            {
              text: this.$t("CustomerWebFeature"),
              path: "/CustomerWebFeature",
              Checkitem: false,
            },
            {
              text: this.$t("HelpandFeedback"),
              path: "/HelpAndFeedback",
              Checkitem: false,
            },
          ],
        },
    ];
    },
  },
  created() {
    if (sessionStorage.locale == "th") {
      this.lang = 0;
    } else if (sessionStorage.locale == "us" || sessionStorage.locale == null) {
      this.lang = 1;
    } else if (sessionStorage.locale == "CN") {
      this.lang = 2;
    } else if (sessionStorage.locale == "VN") {
      this.lang = 3;
    } else if (sessionStorage.locale == "MM") {
      this.lang = 4;
    }

    if (sessionStorage.locale == null) {
      i18n.locale = "en";
    } else {
      i18n.locale = sessionStorage.getItem("locale");
    }

    if (sessionStorage.flag == null) {
      this.currentflag = "us";
    } else {
      this.currentflag = sessionStorage.getItem("flag");
    }

    if (sessionStorage.title == null) {
      this.currentLangTitle = "English";
    } else {
      this.currentLangTitle = sessionStorage.getItem("title");
    }
    // this.changeLocale( i18n.locale , this.currentflag,this.currentLangTitle)
  },

  methods: {
    changed() {
      if (sessionStorage.locale == "th") {
        this.lang = 0;
      } else if (
        sessionStorage.locale == "us" ||
        sessionStorage.locale == null
      ) {
        this.lang = 1;
      } else if (sessionStorage.locale == "cn") {
        this.lang = 2;
      } else if (sessionStorage.locale == "vn") {
        this.lang = 3;
      } else if (sessionStorage.locale == "mm") {
        this.lang = 4;
      }
    },
    changeLocale(locale, flag, title) {
      this.currentflag = flag;
      i18n.locale = locale;
      this.currentLangTitle = title;
      document.cookie = "lang=" + locale;
      document.cookie = "flag=" + flag;
      document.cookie = "title=" + title;
      localStorage.setItem("Templang", title);
      sessionStorage.setItem("locale", locale);
      sessionStorage.setItem("flag", flag);
      sessionStorage.setItem("title", title);
    },
    deleteAllCookies() {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    },
    logout: function () {
      // let expire = "Thu, 01 Jan 1970 00:00:00 GMT;";
      // document.cookie = "username=;" + ";expires=" + expire;
      // document.cookie = "password=;" + ";expires=" + expire;
      // document.cookie = "rememberme=false";
      // document.cookie = ""
      this.deleteAllCookies();
      this.$store.dispatch("logout")
      this.$router.push("/").catch(()=>{});
    },
    get1st(id) {
      for (let j = 0; this.items[id].children.length >= j; j++) {
        //if (this.items[id].children[j].Checkitem == true) {
        this.$router.push(this.items[id].children[j].path).catch(()=>{});
        break;
        //}
      }
    },
    get2nd(id) {
       this.$router.push(this.items[id].path).catch(()=>{}); 
    },
    checkItems(item) {
      for (var i in this.items[item].children) {
        if (
          this.items[item].children[i].path === this.$route.path
        ) {
          return true;
        }
      }
    },
    closemodel() {
      let self = this;
      for (let j = 0; self.items.length; j++) {
        self.items[j].model = false;
      }
    },
  },
};
</script>
<style scoped>
.notiText {
  color: #444444;
  font-size: 12px;
  font-family: Kanit, sans-serif;
}
@media (max-width: 320px) {
  .headtxt {
    margin-left: -1rem !important;
    font-size: 13px !important;
  }
  .headddl {
    margin-left: -2rem !important;
  }
}
::v-deep .v-list-item--link:before {
  background: rgba(0, 0, 0, 0.5);
}
/* ::v-deep .v-list-item--active {
  border-left: 6px solid #f99d20;
  background: rgba(0, 0, 0, 0.5);
} */

.highlighted{
} 
::v-deep .highlighted .v-list-group__header{
  border-left: 6px solid #f99d20;
  background: rgba(0, 0, 0, 0.5);
} 
::v-deep .highlighted .v-list-group__header .v-list-item .v-list-item__content{
  color: #f99d20;
}

::v-deep .highlighted .v-list-group__items .v-list-item .v-list-item__content .v-list-item--active{
  border-left: 6px solid #f99d20;
  background: rgba(0, 0, 0, 0.5);
}
::v-deep .highlighted .v-list-group__items .v-list-item .v-list-item__content .v-list-item--active .v-list-item__title{
  color: #f99d20;
}

::v-deep .v-list .v-list-item--active .v-icon {
  color: #fff;
}
::v-deep.theme--dark.v-list-item--active:hover::before, .theme--dark.v-list-item--active::before{
  opacity: 0%;
}
.lang-title {
  color: #f99d20;
  font-size: 16px;
}
.normal-flag[data-v-05fb4d45] {
  margin-right: -10px;
  -webkit-transform: scale(0.43);
}
</style>
