<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <div style="background-color: #f0f0f7">
      <p style="margin-left: 4px; margin-top: 22px" class="headertext">
        {{ $t("Instruction") }}
      </p>
    </div>
    <v-card class="dialogmargin" style="margin-bottom: 5rem">
      <div class="ml-2 mt-3 pa-4">
        <v-row>
          <span class="ml-3 mt-3"><img :src="images.light" /></span>
          <div class="ml-4 mt-3">
            <p class="mb-0" style="font-weight: bold;">{{ $t("Popupinstruction") }}</p>
            <p style="font-weight: lighter;">{{ $t("DetermineShowPopup") }}</p>
          </div>
          <v-spacer></v-spacer>
          <div class="ml-6 mr-6 mt-4 mb-3"><span>{{ $t("Active") }}</span>
            <input class="ml-3" type="checkbox" v-model="activeshow" @change="Change_Switch()" />
          </div>
        </v-row>
        <div v-show="showbelow">
          <hr class="mr-4 ml-2" />
          <v-row class="ml-2 mt-4">
            <p class="mt-3">{{ $t("Userlogin") }} </p>

            <v-text-field autocomplete="off" color="#F99D20" v-model="availability" class="txtnumbox pt-1 pl-3 ml-3 mr-3"
              @keypress="onlyNumber" min="0" max="98">
              <template v-slot:append>
                <div style="margin-right: 8px; margin-top: 12px">
                  <v-row>
                    <v-btn style="width: 13px; height: 8px" @click="addnum(availability)" icon text>
                      <img src="@/assets/images/tb-up-arrow.png" alt />
                    </v-btn>
                  </v-row>
                  <v-row>
                    <v-btn style="width: 13px; height: 8px" @click="subnum(availability)" icon text>
                      <img src="@/assets/images/tb-down-arrow.png" style="margin-top: 5px" alt />
                    </v-btn>
                  </v-row>
                </div>
              </template>
            </v-text-field>

            <p class="mt-3"> {{ $t("Times") }}.</p>
          </v-row>
        </div>

        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn color="#F99D20" class="text-capitalize" width="120" height="40"
            @click="UpdatePopUp()">{{ $t("save") }}</v-btn>
        </v-card-actions>
      </div>

    </v-card>
    <!-- Loading Dialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>

  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import LoadingDialog from "@/components/Loading.vue";
export default {
  components:{
    LoadingDialog,
  },
  data() {
    return {
      activeshow: true,
      LoadingDialog: false,
      url: enurl.apiUrl,
      showbelow: true,
      availability: 0,
      theID: 0,
      images: {
        light: require("@/assets/images/lightbulb.png"),
        edit: require("@/assets/images/Mask Group 178.png"),
      },
      edit: {
        language_ID: null,
        company_Name: null,
        language_Name: null,
      },
    };
  },
  mounted() {
    this.GetInstructionPopUp();
  },
  methods: {
    Change_Switch() {
      let self = this;
      self.showbelow = self.activeshow;
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    addnum(item) {
      if (item < 98 && item >= 0) {
        this.availability++;
      }
    },
    subnum(item) {
      if (item > 0 && item <= 98) {
        this.availability--;
      }
    },
    GetInstructionPopUp() {
      try {
        let self = this;
        self.LoadingDialog = true;
        axios
          .post(
            `${self.url}PopUp/GetPopUp`
          )
          .then(function (response) {
            self.theID = response.data.data[0].id;
            self.activeshow = response.data.data[0].status;
            self.availability = response.data.data[0].limit_PopUp;
            self.showbelow = self.activeshow;
            self.LoadingDialog = false;
          });
      } catch (error) {
        alert(error);
        self.LoadingDialog = false;
      }
    },
    UpdatePopUp() {
      try {
        let self = this;
        self.LoadingDialog = true;
        let temUpdateData = {
          id: self.theID,
          limit_PopUp: self.availability,
          status: self.activeshow
        };
        axios
          .post(
            `${self.url}PopUp/UpdatePopUp`, temUpdateData
          )
          .then(function (response) {
            alert(response.data.message);
            self.GetInstructionPopUp();
            self.LoadingDialog = false;
          });
      } catch (error) {
        alert(error);
        self.LoadingDialog = false;
      }
    },
  },
};
</script>
  
<style scoped>
.txtnumbox {
  text-decoration: none !important;
  background: #ffffff;
  box-shadow: 0px 0px 6px #00000029;
  height: 37px;
  max-width: 60px;
  opacity: 1 !important;
  border-radius: 4px !important;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 0px;
}

::v-deep.txtnumbox.v-text-field>.v-input__control>.v-input__slot:before {
  border-style: none !important;
  width: 0%;
}

::v-deep.txtnumbox.v-text-field>.v-input__control>.v-input__slot:after {
  border-style: none !important;
  width: 0%;
}

::v-deep.mdi-paperclip::before {
  content: none;
}

@media only screen {
  input {
    -webkit-appearance: none;
    appearance: none;
    width: 64px;
    padding-left: 8px;
    margin: 0;
    border-radius: 16px;
    background: radial-gradient(circle 12px,
        white 100%,
        transparent calc(100% + 1px)) #ccc 16px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
  }

  input::before {
    content: "OFF";
    font: 12px/32px Kanit;
    color: white;
  }

  input:checked {
    padding-left: 33px;
    padding-right: -16px;
    background-color: #f99d20;
    background-position: -16px;
  }

  input:checked::before {
    content: "ON";
  }
}
</style>