<template>
  <div style="background:#fff2e3"> 
  <v-container fluid class="hello">
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="6"
        xl="6"
        align="center"
        class="hidden-sm-and-down ma-0 pa-0"
        style="margin: 0px"
        
      >
        <v-img
          class="image"
          src="@/assets/HomeImage.png"
          :height="customImgHeight"
        ></v-img>

        <!-- <p absoulte
            style="color:#0FA7D8;font-size:80px;font-family:'Kanit', sans-serif;margin-top:650px"
            class="text-center position-sticky" 
          >Optimistic</p>-->
      </v-col>
      <v-col cols="12" md="6" lg="6" xl="6" class="hello-right    pr-12 mr-0 pr-0">
        <div  >
          <v-container fluid>
            <v-row>
              <v-col cols="12" align="center">
                <p class="text-center welcomeToHeader">Welcome to</p>
                <br />
                <p class="text-center optimisticHeader" style>OPTIMISTIC</p>
              </v-col>
              <v-row>
                <v-col cols="12" align="center">
                  <p class="text-center resetPas">
                    {{ $t("ResetYourPassword") }}
                  </p>
                </v-col>
              </v-row>
            </v-row>
            <v-row align="center" justify="center">
              <p class="yourEmail">{{ $t("PleaseEnterYourEmail") }}</p>
            </v-row>
            <v-row justify="center">
              <v-col cols="12" style="flex-basis: 500px">
                <v-alert type="error" v-model="erroralert">{{
                  errorMessage
                }}</v-alert>
                <v-alert type="info" v-model="successalert" style="text-align:left;font-size: 14px;">{{
                  successMessage
                }}</v-alert>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xs="12" sm="6" md="6" xl="6" class="offset-sm-3">
                <v-text-field
                  color="warning"
                  :label="$t('email')"
                  :error-messages="error_Pass"
                  v-model="forget_Password"
                >
                  <template v-slot:prepend-inner>
                    <img src="@/assets/images/Mask Group 192.png" />
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xs="12" sm="6" md="6" xl="6" class="offset-sm-3">
                <v-btn
                  rounded
                  color="#F99D20"
                  block
                  style="height: 52px; font-size: 17px; color: white"
                  depressed
                  class="text-capitalize kanit"
                  @click="resetPassword"
                  >{{ $t("ResetPassword") }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="6" md="6" xl="6" class="offset-sm-3">
                <p
                  class="kanit font17 float-right"
                  style="
                    color: #0fa7d8;
                    text-decoration: underline;
                    font-size: 12px;
                  "
                  @click="backToLogin()"
                >
                  {{ $t("BackToLogin") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-col>
    </v-row>
  </v-container>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import LogTrace from "@/api/Function.js";
export default {
  mixins: [LogTrace],
  data() {
    return {
      customImgHeight: "100%",
      url: enurl.apiUrl,
      forget_Password: "",
      error_Pass: "",
      erroralert: false,
      successalert: false,
      errorMessage: "",
      successMessage: "",
    };
  },
  watch: {
    "forget_Password"(){
      this.error_Pass = [];
    },
  },
  methods: {
    backToLogin() {
      this.$router.push({ name: "Login" }).catch(()=>{});
    },
    validateEmail(email) {
      let valid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return valid.test(email) || "Invalid Email";
    },
    clearMessage: function () {
      this.erroralert = false;
      this.errorMessage = "";
      this.successalert = false;
      this.successMessage = "";
    },
    resetPassword() {
      let self = this;
      self.clearMessage();
      self.error_Pass = !this.forget_Password
        ? "Email is required"
        : "";
      if (self.forget_Password.length >= 1) {
        let emailvali = self.validateEmail(self.forget_Password);
        if (emailvali == true) {
          let tempp = {
            email: self.forget_Password,
          };
          axios
            .post(`${self.url}UserInfo/ForgetPasswordAdmin`, tempp)
            .then(function (response) {
              if (
                response.data.message == "Please check your User Id and Mail."
              ) {
                self.erroralert = true;
                self.errorMessage = "Mail not found in our system!";
              } else {
                self.LogTrace(null, "Forgot Password", 54, "Low");
                self.successalert = true;
                self.successMessage=self.$t("forgetpassw");
                // self.successMessage =
                //   "Please check your inbox for new password. If you did not receive an email, please check your junk mailbox. If the problem still exists, please email us at sales@fusionsol.com";
                self.forget_Password = "";
              }
            }).catch(function(error){
              alert(error);
              self.LogTrace(error, "Forgot Password Fail", 54, "Low");
            })
        } else {
          self.error_Pass = self.validateEmail(self.forget_Password);
          alert("Please fill the correct Email format");
        }
      }
    },
  },
};
</script>
<style scoped>
.container {
  padding: 0px;
}
.hello {
  background-color: #fff2e3;
  padding-left: 10px;
  padding-right: 10px;
  margin:0px;
  max-width: 2000px;
}
@media (min-width: 1179px) {
  .hello-right {
    margin-left: 100px;
  }
}
rowjust {
  margin-top: -1.5rem;
}
.welcomeToHeader {
  color: #0fa7d8;
  font-size: 40px;
  font-family: "Montserrat", sans-serif;
  text-shadow: 0px 3px 6px #00000029;
  font-weight: bold;
}
.optimisticHeader {
  font-size: 60px;
  color: #f99d20;
  font-family: "Montserrat", sans-serif;
  margin-top: -30px;
  font-weight: bold;
}
.resetPas {
  font-size: 20px;
  color: #f99d20;
  font-family: Kanit, Regular;
}
.yourEmail {
  font-size: 14px;
  color: #3c4043;
  font-family: Kanit, Regular;
  margin-left: 10px;
}
.companyLogoCustom {
  min-width: 428px;
  height: 180px;
  background-color: #ffffff;
  padding-top: 80px;
}
.p-custom {
  padding: 0px;
}
@media (min-width: 1179px) {
  .hello-right {
    margin-left: 100px;
  }
}
@media (max-height: 600px) and (max-width: 350px) {
  .welcomeToHeader {
    color: #0fa7d8;
    font-size: 40px;
    font-family: "Montserrat", sans-serif;
    text-shadow: 0px 3px 6px #00000029;
    font-weight: bold;
  }
  .optimisticHeader {
    font-size: 60px;
    color: #f99d20;
    font-family: "Montserrat", sans-serif;
    margin-top: -30px;
    font-weight: bold;
  }
}
@media (max-height: 660px) and (max-width: 300px) {
  .welcomeToHeader {
    color: #0fa7d8;
    font-size: 10px;
    font-family: "Montserrat", sans-serif;
    text-shadow: 0px 3px 6px #00000029;
    font-weight: bold;
  }
  .optimisticHeader {
    font-size: 20px;
    color: #f99d20;
    font-family: "Montserrat", sans-serif;
    margin-top: -30px;
    font-weight: bold;
  }
}
@media (max-height: 570px) and (max-width: 370px) {
  .welcomeToHeader {
    color: #0fa7d8;
    font-size: 30px;
    font-family: "Montserrat", sans-serif;
    text-shadow: 0px 3px 6px #00000029;
    font-weight: bold;
  }
  .optimisticHeader {
    font-size: 40px;
    color: #f99d20;
    font-family: "Montserrat", sans-serif;
    margin-top: -30px;
    font-weight: bold;
  }
}


@media (min-height: 620px) and (min-width: 350px) {
  .hello-right {
    margin-left: 0px;
    height: 104vh;
  }
}

@media (min-height: 820px) and (min-width: 410px) {
  .hello-right {
    margin-left: 0px;
    height: 98vh;
  }
}
@media (min-height: 810px) and (min-width: 370px) {
  .hello-right {
    margin-left: 0px;
    height: 106vh;
  }
}
@media (min-width: 1179px) {
  .hello-right {
    margin-left: 0px;
  }
}

@media (min-width: 1179px) and (min-height:700){
  .hello-right {
    padding-right:100vh;
    margin-left: 0px;
  }
}
.image {
   
  min-height: 100vh;
  width: 100%;
  height: 100vh;
  max-width: 100%;
  max-height: 200vh;
}

@media (max-height: 800px) and (max-width: 1240px) {
  .image {
    min-height: 150vh;
    width: 100%;
    height: 120vh;
    max-width: 100%;
    max-height: 200vh;
  }
}

@media (max-height: 550px) and (max-width: 1240px) {
  .image {
    min-height: 200vh;
    width: 100%;
    height: 110vh;
    max-width: 100%;
    max-height: 200vh;
  }
}


@media (max-height: 400px) and (max-width: 1240px) {
  .image {
     min-height: 200vh;
    width: 100%;
    height:200vh;
    max-width: 100%;
    max-height: 200vh;
  }
}


@media (max-height: 450px) and (min-height:400px) {
  .image {
     
    width: 100%;
    height: 150vh;
    max-width: 100%;
    max-height: 200vh;
  }
}
@media (max-height: 400px) and (min-height:300px) {
  .image {
     
    width: 100%;
    height: 300vh;
    max-width: 100%;
    max-height: 400vh;
  }
}

</style>